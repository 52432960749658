import { useEffect, useState } from "react";
import logo from "../../images/logosNUXWAY-2.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Login.css";
import { Amplify, Auth } from "aws-amplify";
import config from "../../aws-exports";
import RegisterSuccessfully from "../RegistrarUsuario/RegisterSuccessfully";
import useIdleTimer from "../../components/useIdleTimer";
import AboutePage from "../../components/About";
import CryptoJS from "crypto-js";

Amplify.configure(config);

/*
  En esta pagina el usuario se loggea, su no ingresa el usuario
  o contraseña el boton se desactiva.
*/

export function decryptString(encryptedText, secretKey) {
  let base64 = encryptedText.replace(/-/g, "+").replace(/_/g, "/");
  const padding = 4 - (base64.length % 4);
  if (padding !== 4) {
    base64 += "=".repeat(padding);
  }
  const bytes = CryptoJS.AES.decrypt(base64, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  return decrypted;
}

function Login() {
  useIdleTimer();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const [error, setError] = useState({ state: false, message: "" });
  const [confirmedUser, setConfirmedUser] = useState(true);
  const [cognitoUserName, setCognitoUserName] = useState("");
  const location = useLocation();

  const autoSignIn = async () => {
    try {
      console.log("Intentando loggear auto");
      const searchParams = new URLSearchParams(location.search);
      const user = searchParams.get("user");
      console.log("User leido:", user);
      // Desencriptar el texto
      const textoDesencriptado = decryptString(user, "nuxwayservices");
      console.log("Texto desencriptado:", JSON.parse(textoDesencriptado));
      const { email: emailPassed, pass: passPassed } =
        JSON.parse(textoDesencriptado);
      console.log(emailPassed, passPassed);
      try {
        // Utilizar el token directamente para iniciar sesión con Amplify
        const res = await Auth.signIn({
          username: emailPassed,
          password: passPassed,
        });
        var token = res.signInUserSession;
        if (token != null) {
          setError({ state: false, message: null });
          console.log("Token:", token);
          navigate("/pbx-management");
          sessionStorage.setItem("token", token.idToken.jwtToken);
          console.log(token.idToken.jwtToken);
        }
      } catch (error) {
        console.error("Error al iniciar sesión automático:", error);
      }
    } catch (error) {
      // Manejar errores de inicio de sesión automático
      console.error("Error al iniciar sesión automáticamente:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    autoSignIn();
  }, []);

  const buttonHandler = async () => {
    try {
      var user = await Auth.signIn(email.trim(), password.trim());
      var token = user.signInUserSession;
      if (token != null) {
        setError({ state: false, message: null });
        console.log("Token:", token);
        navigate("/pbx-management");
        sessionStorage.setItem("token", token.idToken.jwtToken);
        console.log(token.idToken.jwtToken);
      }
    } catch (err) {
      setError({ state: true, message: err.message });
      if (err.message === "User is not confirmed.") {
        //const userdata = await Auth.resendSignUp(email)
        console.log(user);
        setCognitoUserName(email);
        setError({ state: false, message: null });
        //const us=await Auth.resendSignUp(email)
        setConfirmedUser(false);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return confirmedUser ? (
    <div className="wrapper vh-100">
      {/* Cuadro superior con mensaje */}
      <div>
        <AboutePage />
      </div>

      <nav
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          margin: "10px",
        }}
      >
        <button
          className="btn btn-lg btn-primary btn-block"
          type="button"
          style={{ width: "auto" }}
          onClick={() => {
            navigate("/registry");
          }}
        >
          Sign Up
        </button>
      </nav>
      <div className="login" style={{ marginTop: "-10px" }}>
        <form
          className="col-lg-3 col-md-4 col-10 mx-auto text-center"
          onSubmit={(event) => event.preventDefault()}
        >
          <img className="mb-5" src={logo} width="100%" alt="" />
          <div className="form-group">
            <label className="sr-only">Email</label>
            <input
              type="email"
              id="inputEmail"
              name="email"
              value={email}
              className="form-control form-control-lg"
              placeholder="Email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="sr-only">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              className="form-control form-control-lg"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error.state ? (
            <div className="alert alert-danger" role="alert">
              <span className="fe fe-minus-circle fe-16 mr-2"></span>
              {error.message}
            </div>
          ) : (
            ""
          )}
          <button
            className="btn btn-lg btn-primary btn-block"
            type="submit"
            onClick={buttonHandler}
            disabled={isSubmitting || !email || !password}
          >
            {isSubmitting ? "Signing in..." : "Sign in"}
          </button>
          <div className="mt-5">
            <a href="http://aws.amazon.com/what-is-cloud-computing">
              <img
                src="https://d1.awsstatic.com/logos/aws-logo-lockups/poweredbyaws/PB_AWS_logo_RGB.61d334f1a1a427ea597afa54be359ca5a5aaad5f.png"
                width="80%"
                alt="Powered by AWS Cloud Computing"
              />
            </a>
          </div>
          <p className="mt-5 mb-3 text-muted">
            Nuxway Technology © {new Date().getFullYear()}
          </p>
        </form>
      </div>
    </div>
  ) : (
    <RegisterSuccessfully
      email={email}
      cognitoUserName={cognitoUserName}
      setConfirmedUser={setConfirmedUser}
    />
  );
}
export default Login;
